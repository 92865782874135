@use '@styles/_partials' as *;

.popupContainer {
  width: 100%;
  max-width: 500px;
  // background-color: gray !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding-top: pxToRem(50px) !important;
  margin: auto;
  cursor: initial;

  .popupContentWrapper {
    padding: 0 pxToRem(20px);
  }

  .popupLabel {
    @include h2();
    margin-bottom: pxToRem(10px);
  }

  .popupDescription {
    margin-bottom: pxToRem(10px);
  }

  .buttonContainer {
    .buttonWrapper {
      display: inline-block;
      margin-top: pxToRem(10px);
    }

    .popupButton {
      margin: 0 40px;
    }
  }

  .closePopup {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    right: 0;
    background: transparent;
    padding: 0;
    z-index: 1;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    #close {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $white;
      color: $white;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      @include font-size(30px);
      @include line-height(30);

      span {
        background: white;
        height: 20px;
        position: relative;
        width: 2px;
        transform: rotate(45deg);
      }

      span:after {
        background: white;
        content: '';
        height: 2px;
        left: -9px;
        position: absolute;
        top: 9px;
        width: 20px;
      }
    }

    &:hover #close::after {
      content: '';
      position: absolute;
      overflow: visible;
      height: 40px;
      width: 40px;
      top: 0;
      left: 0;
      background-image: radial-gradient(
        circle at center center,
        rgba($white, 0.5) 0px,
        rgba($white, 0) 70%
      );
      z-index: -1;
    }
  }
}
