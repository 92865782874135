@use '@styles/_partials' as *;
@use './Footer.rtl.scss';

.getCrackinContainer {
  @function bgHalo($radius) {
    @return radial-gradient(
      circle $radius at center $radius,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(86, 86, 86, 0.125) 72%,
      rgba(21, 21, 21, 0) 100%
    );
  }
  background: bgHalo(70px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: pxToRem(60px);
  @include respond-to($lg) {
    background: bgHalo(90px);
    padding-bottom: pxToRem(32px);
  }
}

.openingShellImageContainer {
  position: relative;
  width: 87px;
  height: 104px;
  @include respond-to($lg) {
    width: 112px;
    height: 134px;
  }
}

.getCrackinText {
  color: $primary;
  text-transform: none;
  font-family: $primaryFontBold;

  @include font-size(16px);
  @include line-height(22);

  @include respond-to($lg) {
    @include font-size(22px);
    @include line-height(30);
  }
}

footer.footer {
  background-color: $black;
  padding-top: pxToRem(42px);
  position: relative;
  @include respond-to($lg) {
    padding-top: pxToRem(62px);
  }

  & > :global(.container) {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    align-items: center;
  }

  &::before {
    content: '';
    height: 8px;
    width: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(
      90deg,
      rgb(21, 21, 21) 0%,
      rgb(53, 53, 53) 49%,
      rgb(21, 21, 21) 100%
    );
  }
}

.mainContent {
  width: 100%;
  padding-bottom: pxToRem(16px);
  @include respond-to($sm) {
    max-width: 700px;
  }
  @include respond-to($lg) {
    max-width: none;
  }

  @include respond-to($lg) {
    width: 100%;
    display: flex;
    padding-bottom: pxToRem(30px);
    justify-content: space-between;
  }
}

.ownLogo {
  width: 100%;
  display: block;
  text-align: center;
  color: $white;
  margin: 0 auto pxToRem(20px);
  @include respond-to($lg) {
    margin: 0;
    width: 30%;
    text-align: left;
  }

  svg {
    height: 32px;
    width: 112px;
  }
}

.siteLinks {
  display: flex;
  justify-content: space-around;
  height: fit-content;
  @include respond-to($lg) {
    min-width: 50%;
    justify-content: space-between;
  }
}

.siteLinksColumn {
  display: flex;
  flex-direction: column;
  color: $white;
  padding-bottom: pxToRem(26px);

  @include respond-to($lg) {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: pxToRem(90px);
    &:last-child {
      margin-right: 0;
    }
  }

  strong {
    font-size: 14px;
    line-height: 19px;
    padding-bottom: pxToRem(16px);
    text-transform: uppercase;

    @include respond-to($sm) {
      font-size: 16px;
      line-height: 22px;
      padding-bottom: pxToRem(13px);
    }
  }

  a {
    font-size: 12px;
    line-height: 15px;
    padding-bottom: pxToRem(13px);
    color: inherit;
    display: inline-block;
    text-transform: capitalize;

    @include respond-to($sm) {
      font-size: 14px;
      line-height: 17px;
    }

    &:not(:last-of-type) {
      padding-bottom: pxToRem(13px);

      @include respond-to($sm) {
        padding-bottom: pxToRem(15px);
      }
    }

    transition: opacity 0.05s;

    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }

  .siteLinksColumnWrap {
    @include respond-to($md) {
      display: flex;
      ul {
        margin-right: pxToRem(40px);
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.siteLinksSpacer {
  height: 20px;
}

.brands {
  grid-area: brands;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top: solid 1px $darkGray;
  padding: pxToRem(20px) 0;
  width: 100%;
  @include respond-to($md) {
    padding: pxToRem(35px) 0;
  }
}

.brandsRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: pxToRem(30px);

  @include respond-to($sm) {
    justify-content: space-around;
  }

  @include respond-to($md) {
    padding-top: 0;
    justify-content: center;
  }
}

.brandLink {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: pxToRem(20px);
  &:first-child {
    width: 100%;
  }
  transition: opacity 0.05s;

  &:hover,
  &:focus {
    opacity: 0.75;
  }

  @include respond-to($sm) {
    width: unset;
    padding-bottom: 0;
  }

  @include respond-to($md) {
    margin-right: pxToRem(60px);
    &:first-child {
      width: unset;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.bottomItems {
  width: 100%;
  border-top: solid 1px $darkGray;
  display: grid;
  grid-template-areas:
    'legal-links'
    'copyright';
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  padding: pxToRem(28px) 0;
  color: $ltGray;

  @include respond-to($lg) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'copyright legal-links';
    font-size: 14px;
    line-height: 19px;
    padding-top: pxToRem(10px);
    padding-bottom: pxToRem(38px);
  }

  & > * {
    flex: 1;
  }

  a {
    color: inherit;
    &:visited {
      color: inherit;
    }
  }
}

.hiddenSelect {
  visibility: hidden;
  position: absolute;
}

.legalLinks {
  grid-area: legal-links;
  padding-bottom: pxToRem(20px);
  text-align: center;
  @include respond-to($lg) {
    white-space: nowrap;
    padding-bottom: 0;
    text-align: end;
  }

  li {
    display: inline-block;
  }

  a {
    padding: 0 pxToRem(6px);

    &:hover,
    &:focus,
    &:active {
      // 0.8 * 0.75 === 0.6, which is the minimum opacity for good contrast
      opacity: 0.8;
    }
  }
}

.copyrightLink {
  grid-area: copyright;
  @include respond-to($lg) {
    text-align: start;
  }
}

.visuallyHidden {
  @include visuallyHidden();
}

.socialIcons {
  display: flex;
  width: 100px;
  flex-wrap: wrap;
  li {
    width: 29px;
    height: 37px;
    padding-top: pxToRem(8px);
    margin-right: pxToRem(10px);
    &:last-child {
      margin: 0;
    }
    span {
      display: none;
    }
  }
}
