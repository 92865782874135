@mixin respond-to($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin respond-to-max-width($breakpoint) {
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin respond-to-min-height($breakpoint) {
  @media only screen and (min-height: $breakpoint) {
    @content;
  }
}

@mixin respond-to-max-height($breakpoint) {
  @media only screen and (max-height: $breakpoint) {
    @content;
  }
}

@mixin line-height($lh, $important: false) {
  $lhUnit: if(type-of($lh) == 'number', unit($lh), false);
  $baseValue: 16 !default;
  $baseLhUnit: rem !default;

  @if $lhUnit == 'px' {
    $lh: math.div($lh, ($lh * 0 + 1));
  } @else if $lhUnit == 'rem' {
    $lh: math.div($lh, ($lh * 0 + 1, $baseValue));
  }

  // set the px or rem value
  $lhStatic: if($baseLhUnit == rem, #{math.div($lh, $baseValue)}rem, #{$lh}px);

  // Add !important suffix if needed
  $lhSuffix: if($important, ' !important', '');

  line-height: #{$lhStatic}#{$lhSuffix};
}

@mixin letter-spacing($lh, $important: false) {
  $lhUnit: if(type-of($lh) == 'number', unit($lh), false);
  $baseValue: 16 !default;
  $baseLhUnit: rem !default;

  @if $lhUnit == 'px' {
    $lh: math.div($lh, ($lh * 0 + 1));
  } @else if $lhUnit == 'rem' {
    $lh: math.div($lh, ($lh * 0 + 1, $baseValue));
  }

  // set the px or rem value
  $lhStatic: if($baseLhUnit == rem, #{math.div($lh, $baseValue)}rem, #{$lh}px);

  // Add !important suffix if needed
  $lhSuffix: if($important, ' !important', '');

  letter-spacing: #{$lhStatic}#{$lhSuffix};
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin bgRadialGradient(
  $gradientSize: 435px,
  $centerColor: #fff,
  $innerColor: #565656,
  $outerColor: #40311f
) {
  &::before {
    height: $gradientSize;
    width: $gradientSize;
    position: absolute;
    z-index: 1;
    top: calc((100% - #{$gradientSize}) / 2);
    left: calc((100% - #{$gradientSize}) / 2);
    content: '';
    opacity: 0.2;
    animation: scaleToFull 0.5s;
    background-image: radial-gradient(
      closest-side at 50% 50%,
      $centerColor 5%,
      rgba($innerColor, 0.25) 83%,
      rgba($outerColor, 0) 95%
    );
    @content;
  }
}

@keyframes scaleToFull {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes revealItemFromRight {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes revealItemFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes revealItem {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*TITLE MIXINS*/
@mixin h1() {
  font-family: $primaryFontHeavy;
  color: $white;
  margin: 10px 0;
  letter-spacing: 7.2px;
  @include font-size(28px);
  @include line-height(34);

  @include respond-to($md) {
    @include font-size(34px);
    @include line-height(49);
    letter-spacing: 7.6px;
  }

  @include respond-to($lg) {
    @include font-size(40px);
    @include line-height(50);
    letter-spacing: 8px;
  }
}

@mixin h2() {
  font-family: $primaryFontBold;
  letter-spacing: inherit;
  @include font-size(28px);
  @include line-height(36);
  @include respond-to($lg) {
    @include font-size(32px);
    @include line-height(44);
  }
}

@mixin h3() {
  font-family: $primaryFontBold;
  @include font-size(16px);
  @include respond-to($lg) {
    @include font-size(22px);
  }
}

@mixin h4() {
  font-family: $primaryFontBold;
}

@mixin h5() {
  font-family: $primaryFontBold;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  @include respond-to($lg) {
    line-height: 22px;
  }
}

@mixin h6() {
  font-family: $primaryFontBold;
  font-weight: 700;
}

@mixin visuallyHidden() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@function pxToRem($pxValue) {
  @return math.div($pxValue, ($font-size-base * 1px)) * 1rem;
}

@mixin borderLeft($borderOffset: 2rem) {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background-color: $ltGray;
    top: math.div($borderOffset, 2);
    height: calc(100% - $borderOffset);
    width: 1px;
    left: 0;
  }
}

@mixin borderLeftShow() {
  &::after {
    content: none;
  }
}

@mixin borderLeftHide() {
  &::after {
    content: none;
  }
}

@mixin fullAbsoute() {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@mixin body-font() {
  @include font-size(18px);
  @include line-height(20);

  @include respond-to($lg) {
    @include font-size(20px);
    @include line-height(28);
  }
}
