@use '@styles/_partials' as *;

:global(html[dir='rtl']) .footer {
  .ownLogo {
    @include respond-to($lg) {
      text-align: right;
    }
  }

  .siteLinksColumn {
    @include respond-to($lg) {
      margin-right: initial;
      margin-left: pxToRem(90px);
    }
  }

  .siteLinksColumnWrap {
    @include respond-to($md) {
      ul {
        margin-right: initial;
        &:last-child {
          margin-right: pxToRem(40px);
        }
      }
    }
  }

  .socialIcons {
    li {
      margin-right: initial;
      &:last-child {
        margin-right: pxToRem(10px);
      }
    }
  }

  .brandLink {
    @include respond-to($md) {
      margin-right: pxToRem(60px);
      &:first-child {
        margin-right: initial;
      }
    }
  }
}
